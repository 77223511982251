@font-face {
  font-family: "Gotham-Ultra";
  src: url("./assets/fonts/Gotham-Ultra.ttf");
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("./assets/fonts/Gotham-Medium.otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
li,
input {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  border: none;
  outline: none;
}

ul {
  width: 100%;
  padding: 0;
  margin: 0;
}

button {
  padding: 11px 20px;
  border-radius: 100px;
  background: #323946;
  color: white;
  border: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

li,
li a {
  list-style-type: none;
  text-decoration: none;
  color: white;
  box-sizing: border-box;
}

a:focus,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

/* HIDE INPUT NUMBER ARROWS */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/* HIDE INPUT NUMBER ARROWS */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: white;
  box-shadow: 0 0 0 30px white inset !important;
}
